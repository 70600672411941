import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Router from './routes';
import ThemeProvider from './theme';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// ----------------------------------------------------------------------

export default function App() {
  return (
    <Elements stripe={stripePromise}>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </Elements>
  );
}
