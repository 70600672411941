/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
import './subcription.css'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Subcription(props) {
    const { summary } = props;
    const navigate = useNavigate();

    return (
        <>
            <>
                <main>
                    <section className="section-4">
                        <h5 className='text-start w-100 mb-1'><i className="me-4" onClick={() => navigate(-1)}><KeyboardBackspaceIcon color='primary' fontSize='small' /></i>Transactions</h5>
                        {/* <div className='w-100 ps-5'>
                            <h5 className='text-start'>Summary</h5>
                        </div> */}                   
                        
                        {summary.this_month ? <div className="section-4-2">
                            <div className="section-main">
                                <div className="pricing-body">
                                    <div className="pricing-body-plans">
                                        <div className="active" id="pricing__monthly__plan">
                                            <div>
                                                <div className="card">
                                                    <div className="card-header" style={{ background: 'none', border: 'none' }}>
                                                        <p>This month so far</p>
                                                        <p>({summary.this_month.startDate} - Present)</p>
                                                        <h2 className="card-price">${summary.this_month.totalAmount}</h2>
                                                    </div>
                                                    <div className="card-body">
                                                        <ul>
                                                            <li>
                                                               <p>Total calls</p>
                                                                <p className="price-color">{summary.this_month.totalCalls}</p>
                                                            </li>
                                                             <li>
                                                                <p>Total duration</p>
                                                                <p className="price-color">{summary.this_month.totalDuration} mins</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" style={{ background: 'none', border: 'none' }}>
                                                        <p>Last month</p>
                                                        <p>({summary.last_month.month})</p>
                                                        <h2 className="card-price">${summary.last_month.totalAmount}</h2>
                                                    </div>
                                                    <div className="card-body">
                                                        <ul>
                                                            <li>
                                                                <p>Total calls</p>
                                                                <p className="price-color">{summary.last_month.totalCalls}</p>
                                                            </li>
                                                            <li>
                                                                <p>Total duration</p>
                                                                <p className="price-color">{summary.last_month.totalDuration} mins</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" style={{ background: 'none', border: 'none' }}>
                                                        <p>The previous month</p>
                                                        <p>({summary.the_previous_month.month})</p>
                                                        <h2 className="card-price">${summary.the_previous_month.totalAmount}</h2>
                                                    </div>
                                                    <div className="card-body">
                                                        <ul>
                                                            <li>
                                                                <p>Total calls</p>
                                                                <p className="price-color">{summary.the_previous_month.totalCalls}</p>
                                                            </li>
                                                             <li>
                                                                <p>Total duration</p>
                                                                <p className="price-color">{summary.the_previous_month.totalDuration} mins</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : <div className="d-flex justify-content-center align-items-center">
                        <CircularProgress style={{ color: '#000', fontSize: '24px' }} />
                      </div>}
                    </section>
                </main>
            </>

        </>
    )
}
export default Subcription

