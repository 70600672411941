import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, createTheme, ThemeProvider, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Swal from 'sweetalert2';
import { Instance } from '../../../config/Http';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '2px solid white', // Replace 'red' with your desired border color
            },
            '&:hover fieldset': {
              borderColor: '#5756a2', // Replace 'blue' with your desired hover color
            },
          },
        },
      },
    },
  },
});

export default function LoginForm() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [password, setPassword] = useState('');
  const { email } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const locationId = queryParams.get('locationId');

  const Navigate = useNavigate();

  useEffect(() => {
    if (!email || !locationId) {
      navigate('/404');
    } else {
      const emailLower = email.toLowerCase();
      if (validateEmail(emailLower)) {
        const previousEmail = localStorage.getItem('email');
        if (previousEmail) {
          if (previousEmail !== emailLower) {
            Instance.get(`auth/checkEmail/${emailLower}`)
              .then((res) => {
                if (!res.data.emailExist) {
                  Swal.fire({
                    title: 'Account not found',
                    text: 'There is no account regarding this email you have to register first.',
                    icon: 'error',
                    dangerMode: true,
                  });
                  localStorage.clear();
                }
              })
              .catch((err) => {});
          } else {
            const sessionExpired = localStorage.getItem('sessionExpired');
            if (!sessionExpired) {
              const auth = localStorage.getItem('accessToken');
              if (auth) {
                navigate(`/dashboard/app/`);
              }
            }
          }
        }
      }
    }
  }, [email]);

  const landlogin = () => {
    if (email === '' || password === '') {
      Swal.fire({
        title: 'Password is required',
        icon: 'error',
      });
    } else {
      setLoader(true);
      Instance.post(`auth/login`, { email: email.toLowerCase(), password })
        .then((response) => {
          localStorage.setItem('accessToken', response?.data?.accessToken);
          localStorage.setItem('refreshToken', response?.data?.refreshToken);
          localStorage.setItem('locationId', locationId);
          localStorage.removeItem('sessionExpired');
          localStorage.setItem('email', email.toLowerCase());
          setLoader(false);

          if (response.status === 200) {
            Navigate('/dashboard/app');
            setLoader(false);
          } else {
            setLoader(false);
          }
        })
        .catch((resError) => {
          setLoader(false);
          Swal.fire({
            title: resError.response.data.message,
            icon: 'error',
          });
        });
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const validateEmail = (email) => {
    const reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(email);
  };

  if (!validateEmail(email)) {
    return (
      <Typography variant="h3" sx={{ mt: 5, mb: 2, mx: 'auto', color: 'black' }}>
        Email should be valid and url will be /login/:email
      </Typography>
    );
  }

  return (
    <>
      <form onSubmit={landlogin}>
        <Stack spacing={3}>
          <ThemeProvider theme={theme}>
            <div>
              Account: <strong>{locationId}</strong>
              <br />
              Email: <strong>{email.toLowerCase()}</strong>
            </div>
            <TextField
              name="Password"
              label="Password"
              placeholder="Enter account password"
              onChange={(e) => setPassword(e.target.value)}
              variant="filled"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{}}>
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className="mt-0 d-flex flex-row-reverse">
              <Link className="my-2 text-black" to={`/forgot/${email}?locationId=${locationId}`}>
                <small>Forgot password?</small>
              </Link>
            </div>
          </ThemeProvider>
        </Stack>

        <LoadingButton
          loading={loader}
          loadingPosition="start"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ marginTop: 5, color: '#fff', backgroundColor: '#012367' }}
          onClick={landlogin}
        >
          Login
        </LoadingButton>
      </form>
    </>
  );
}
