import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, createTheme, ThemeProvider, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Swal from 'sweetalert2';
import { Instance } from '../../../config/Http';
import Iconify from '../../../components/iconify';
// import Swal from 'sweetalert2';

// ----------------------------------------------------------------------

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '2px solid white', // Replace 'red' with your desired border color
            },
            '&:hover fieldset': {
              borderColor: '#5756a2', // Replace 'blue' with your desired hover color
            },
          },
        },
      },
    },
  },
});

export default function OtpForm() {
  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const Navigate = useNavigate();
  const { email } = useParams();

  const queryParams = new URLSearchParams(window.location.search);
  const locationId = queryParams.get('locationId');

  const landlogin = () => {
    setLoader(true);
    if (password === confirmPassword) {
      Instance.post(`auth/changePassword`, { email, otp, password, confirmPassword })
        .then((response) => {
          setLoader(false);
          if (response.data.status) {
            Swal.fire({
              title: 'Good job!',
              text: 'Password changed successfully!',
              icon: 'success',
              button: 'Ok',
            });
            Navigate(`/login/${email}?locationId=${locationId}`);
            setLoader(false);
          } else {
            setLoader(false);
            Swal.fire({
              title: 'Oops!',
              text: response.data.message,
              icon: 'error',
              button: 'Ok',
            });
          }
        })
        .catch((resError) => {
          setLoader(false);
          Swal.fire({
            title: resError.response.data.message,
            icon: 'error',
          });
        });
    } else {
      setLoader(false);
      Swal.fire({
        title: 'Password does not match',
        text: 'Password and Confirm Password does not match',
        icon: 'error',
        dangerMode: true,
      });
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <div>
          Account: <strong>{locationId}</strong>
          <br />
          Email: <strong>{email.toLowerCase()}</strong>
        </div>
        <ThemeProvider theme={theme}>
          <TextField
            name="otp"
            label="OTP"
            color="success"
            onChange={(e) => setOtp(e.target.value)}
            value={otp}
            variant="filled"
          />
        </ThemeProvider>
        <ThemeProvider theme={theme}>
          <TextField
            type="password"
            name="password"
            label="New Password"
            color="success"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            variant="filled"
          />
        </ThemeProvider>
        <ThemeProvider theme={theme}>
          <TextField
            type="password"
            name="confirmPassword"
            label="Confirm Password"
            color="success"
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
            variant="filled"
          />
        </ThemeProvider>
      </Stack>

      <LoadingButton
        loading={loader}
        loadingPosition="start"
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        sx={{ marginTop: 5, color: '#fff', backgroundColor: '#012367' }}
        onClick={landlogin}
      >
        Save new password
      </LoadingButton>
    </>
  );
}
