/* eslint-disable react/prop-types */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/button-has-type */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-boolean-value */
import React, { useRef, useState } from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
// import Swal from "sweetalert2";
// import { Instance } from "src/config/Http";


const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },

        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

export default function MySwiper({ cards = [], handleMakeCardPrimary = () => { }, setIndex = () => {}, index }) {
    const swiperRef = useRef(null);

    const handlePaginationClick = (index) => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(index);
        }
    };

    const handlePrevClick = () => {
        if (swiperRef.current && swiperRef.current.swiper && index > 1) {
            swiperRef.current.swiper.slidePrev();
            setIndex(index - 1);
        }
    };

    const handleNextClick = () => {
        if (swiperRef.current && swiperRef.current.swiper && index < cards.length) {
            swiperRef.current.swiper.slideNext();
            setIndex(index + 1);
        }
    };

    const handleChangeIsPrimary = (isChecked, id) => {
        if (isChecked) {
            handleMakeCardPrimary(id)
        }
    }

    return (
        <>
            <div>
                <Swiper
                    ref={swiperRef}
                    watchSlidesProgress={true}
                    slidesPerView={1}
                    className="mySwiper"
                    pagination={{
                        clickable: true,
                        renderBullet: function (index, className) {
                            return (
                                // eslint-disable-next-line react/self-closing-comp
                                <span
                                    className={className}
                                    onClick={() => handlePaginationClick(index)}
                                ></span>
                            );
                        },
                    }}
                >
                    {cards.map(val => (
                        <SwiperSlide key={val.id} >
                            <div className='subscription-card'>
                                <div className='d-flex justify-content-end'>
                                    <img src={`/assets/images/${val.brand}.svg`} className="img-fluid" alt="visa" width={'10%'}/>
                                </div>
                                <p>Card Number</p>
                                <div className='d-flex justify-content-between w-100 align-items-center'>
                                    <div className='d-flex justify-content-between w-100 align-items-center pt-3 px-4 mb-3' style={{ background: 'hsla(0,0%,100%,.11)' }}>
                                        <h6 className='fw-lighter'>***</h6>
                                        <h6 className='fw-lighter'>***</h6>
                                        <h6 className='fw-lighter'>***</h6>
                                        <h6 className='fw-lighter'>{val.last4}</h6>

                                    </div>
                                </div>
                                <div className='d-flex w-100 gap-4'>
                                    <div className='w-50'>
                                        <p>Name</p>
                                        <div className='d-flex justify-content-center w-100 align-items-center pt-3' style={{ background: 'hsla(0,0%,100%,.11)' }}>
                                            <p className='fw-lighter'> {val.name}</p>
                                        </div>
                                    </div>
                                    <div className='w-25'>
                                        <p>CVV</p>
                                        <div className='d-flex justify-content-center w-100 align-items-center pt-3' style={{ background: 'hsla(0,0%,100%,.11)' }}>
                                            <p className='fw-lighter'>***</p>
                                        </div>
                                    </div>
                                    <div className='w-25'>
                                        <p>Expiry Date</p>
                                        <div className='d-flex justify-content-center w-100 align-items-center pt-3' style={{ background: 'hsla(0,0%,100%,.11)' }}>
                                            <p className='fw-lighter'>{val.exp_month} / {val.exp_year}</p>
                                        </div>
                                    </div>


                                </div>
                                <div className='w-full d-flex justify-content-between align-items-center'>
                                    {val.isPrimary ?
                                        <p className="mt-4">Primary Card</p>
                                        :
                                        <p className="mt-4">Make this card primary</p>

                                    }
                                    {/* <div className='d-flex justify-content-end align-items-center mt-2'> */}
                                    {/* <h6>Make this card primary</h6> */}
                                    {val.isPrimary ? ''
                                        : <FormControlLabel
                                            className='ms-2 mt-4'
                                            control={<Android12Switch defaultChecked />}
                                            onChange={(e) => handleChangeIsPrimary(e.target.checked, val.id)}
                                            checked={val.isPrimary}
                                        />
                                    }
                                    {/* </div> */}
                                </div>

                            </div>
                        </SwiperSlide>
                    ))}
                    {/* <SwiperSlide>
                        <div className='subscription-card'>
                        <div className='d-flex justify-content-end'>
                        <h4>Visa</h4>
                        </div>
                        <p>Card Number</p>
                        <div className='d-flex justify-content-between w-100 align-items-center'>
                        <div className='d-flex justify-content-between w-100 align-items-center pt-3 px-4 mb-3' style={{ background: 'hsla(0,0%,100%,.11)' }}>
                        <h6 className='fw-lighter'>***</h6>
                                    <h6 className='fw-lighter'>***</h6>
                                    <h6 className='fw-lighter'>***</h6>
                                    <h6 className='fw-lighter'>647</h6>
                                    </div>
                                    </div>
                            <div className='d-flex w-100 gap-4'>
                            <div className='w-50'>
                                    <p>Name</p>
                                    <div className='d-flex justify-content-center w-100 align-items-center pt-3' style={{ background: 'hsla(0,0%,100%,.11)' }}>
                                    <p className='fw-lighter'> D D D D</p>
                                    </div>
                                    </div>
                                    <div className='w-25'>
                                    <p>CVV</p>
                                    <div className='d-flex justify-content-center w-100 align-items-center pt-3' style={{ background: 'hsla(0,0%,100%,.11)' }}>
                                        <p className='fw-lighter'>***</p>
                                    </div>
                                </div>
                                <div className='w-25'>
                                    <p>Expiry Date</p>
                                    <div className='d-flex justify-content-center w-100 align-items-center pt-3' style={{ background: 'hsla(0,0%,100%,.11)' }}>
                                        <p className='fw-lighter'>11 / 28</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='subscription-card'>
                            <div className='d-flex justify-content-end'>
                                <h4>Visa</h4>
                            </div>
                            <p>Card Number</p>
                            <div className='d-flex justify-content-between w-100 align-items-center'>
                                <div className='d-flex justify-content-between w-100 align-items-center pt-3 px-4 mb-3' style={{ background: 'hsla(0,0%,100%,.11)' }}>
                                    <h6 className='fw-lighter'>***</h6>
                                    <h6 className='fw-lighter'>***</h6>
                                    <h6 className='fw-lighter'>***</h6>
                                    <h6 className='fw-lighter'>647</h6>
                                </div>
                            </div>
                            <div className='d-flex w-100 gap-4'>
                                <div className='w-50'>
                                    <p>Name</p>
                                    <div className='d-flex justify-content-center w-100 align-items-center pt-3' style={{ background: 'hsla(0,0%,100%,.11)' }}>
                                        <p className='fw-lighter'> D D D D</p>
                                    </div>
                                </div>
                                <div className='w-25'>
                                    <p>CVV</p>
                                    <div className='d-flex justify-content-center w-100 align-items-center pt-3' style={{ background: 'hsla(0,0%,100%,.11)' }}>
                                        <p className='fw-lighter'>***</p>
                                    </div>
                                </div>
                                <div className='w-25'>
                                    <p>Expiry Date</p>
                                    <div className='d-flex justify-content-center w-100 align-items-center pt-3' style={{ background: 'hsla(0,0%,100%,.11)' }}>
                                        <p className='fw-lighter'>11 / 28</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='subscription-card'>
                            <div className='d-flex justify-content-end'>
                                <h4>Visa</h4>
                            </div>
                            <p>Card Number</p>
                            <div className='d-flex justify-content-between w-100 align-items-center'>
                                <div className='d-flex justify-content-between w-100 align-items-center pt-3 px-4 mb-3' style={{ background: 'hsla(0,0%,100%,.11)' }}>
                                    <h6 className='fw-lighter'>***</h6>
                                    <h6 className='fw-lighter'>***</h6>
                                    <h6 className='fw-lighter'>***</h6>
                                    <h6 className='fw-lighter'>647</h6>
                                </div>
                            </div>
                            <div className='d-flex w-100 gap-4'>
                                <div className='w-50'>
                                    <p>Name</p>
                                    <div className='d-flex justify-content-center w-100 align-items-center pt-3' style={{ background: 'hsla(0,0%,100%,.11)' }}>
                                        <p className='fw-lighter'> D D D D</p>
                                    </div>
                                </div>
                                <div className='w-25'>
                                    <p>CVV</p>
                                    <div className='d-flex justify-content-center w-100 align-items-center pt-3' style={{ background: 'hsla(0,0%,100%,.11)' }}>
                                        <p className='fw-lighter'>***</p>
                                    </div>
                                </div>
                                <div className='w-25'>
                                    <p>Expiry Date</p>
                                    <div className='d-flex justify-content-center w-100 align-items-center pt-3' style={{ background: 'hsla(0,0%,100%,.11)' }}>
                                        <p className='fw-lighter'>11 / 28</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </SwiperSlide> */}
                </Swiper>
                <div className="d-flex justify-content-center mt-3">
                    <button className="border-0" style={{ background: 'none' }} onClick={handlePrevClick}>
                        <IoIosArrowBack />
                    </button>
                    <button className="border-0" style={{ background: 'none' }}>
                        {index} / {cards.length}
                    </button>
                    <button className="border-0" style={{ background: 'none' }} onClick={handleNextClick}>
                        <IoIosArrowForward />
                    </button>
                </div>
            </div >
        </>
    );
}
