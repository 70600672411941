import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Avatar, Box, Container, Typography } from '@mui/material';
// sections
// import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ForgotForm from '../sections/auth/Forgot/ForgotForm';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    backgroundColor: 'whitesmoke',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 600,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(1, 5),
}));

// ----------------------------------------------------------------------

export default function ForgotPage() {
  const { email } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const locationId = queryParams.get('locationId');

  const navigate = useNavigate();
  useEffect(() => {
    if (!email || !locationId) {
      navigate('/404');
    }
  }, [email]);

  return (
    <>
      <Helmet>
        <title> Forgot </title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <div className="rounded" style={{ background: 'white', padding: '30px' }}>
              <Typography clas variant="h3" sx={{ mt: 5, mb: 8, mx: 'auto', color: 'black' }}>
                Reset your password
              </Typography>
              <ForgotForm />
              <div className="d-flex flex-column" style={{ marginTop: '20px' }}>
                <Link className="my-2 text-black" to={`/login/${email}?locationId=${locationId}`}>
                  Already have an account? Click here to login
                </Link>
              </div>
            </div>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
