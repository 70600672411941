import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, createTheme, ThemeProvider, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Swal from 'sweetalert2';
import { Instance } from '../../../config/Http';
import Iconify from '../../../components/iconify';
// import Swal from 'sweetalert2';

// ----------------------------------------------------------------------

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '2px solid white', // Replace 'red' with your desired border color
            },
            '&:hover fieldset': {
              borderColor: '#5756a2', // Replace 'blue' with your desired hover color
            },
          },
        },
      },
    },
  },
});

export default function ForgotForm() {
  const [loader, setLoader] = useState(false);
  const { email } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const locationId = queryParams.get('locationId');

  const Navigate = useNavigate();

  useEffect(() => {
    if (validateEmail(email)) {
      localStorage.setItem('email', email);
      Instance.get(`auth/checkEmail/${email}`)
        .then((res) => {
          if (!res.data.emailExist) {
            Swal.fire({
              title: 'Account not found',
              text: 'There is no account regarding this email you have to register first.',
              icon: 'error',
              dangerMode: true,
            });
          }
        })
        .catch((err) => {});
    }
  }, [email]);

  const landlogin = () => {
    setLoader(true);
    Instance.get(`auth/forgotPassword/${email}`)
      .then((response) => {
        setLoader(false);
        if (response.data.status) {
          Swal.fire({
            title: 'Good job!',
            text: 'please check your email',
            icon: 'success',
            button: 'Ok',
          });
          Navigate(`/otp/${email}?locationId=${locationId}`);
          setLoader(false);
        } else {
          Swal.fire({
            title: 'Error',
            text: response.data.message,
            icon: 'error',
            button: 'Ok',
          });
          setLoader(false);
        }
      })
      .catch((resError) => {
        setLoader(false);
        Swal.fire({
          title: resError.response.data.message,
          icon: 'error',
        });
      });
  };

  const validateEmail = (email) => {
    const reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(email);
  };

  if (!validateEmail(email)) {
    return (
      <Typography variant="h3" sx={{ mt: 5, mb: 2, mx: 'auto', color: 'black' }}>
        Email should be valid and url will be /login/:email
      </Typography>
    );
  }

  return (
    <>
      <Stack spacing={3}>
        <div>
          Account: <strong>{locationId}</strong>
          <br />
          Email: <strong>{email.toLowerCase()}</strong>
        </div>
        <LoadingButton
          loading={loader}
          loadingPosition="start"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ marginTop: 5, color: '#fff', backgroundColor: '#012367' }}
          onClick={landlogin}
        >
          Send reset code to email
        </LoadingButton>
      </Stack>
    </>
  );
}
