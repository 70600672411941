import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';
import Billing from '../components/Billing/Billing';
// import Wallets from '../components/wallets/Wallets';
// import CallHistoryTable from "../components/materialTable/CallHistoryTable"

export default function DashboardAppPage() {
  return (
    <>
      <Helmet>
        <title>VoiceAI Usage Dashboard</title>
      </Helmet>

      <Container maxWidth="xl" style={{ background: 'whitesmoke' }}>
        <Billing />
      </Container>
    </>
  );
}
