function formatMinutesToMinutesAndSeconds(minutes) {
  let formattedResult;

  if (minutes > 1) {
    // Splitting into integer and decimal parts
    const integerPart = Math.floor(minutes);
    const decimalPart = minutes - integerPart;

    // Converting decimal part to seconds
    const seconds = Math.round(decimalPart * 60);

    // Formatting the result
    formattedResult = `${integerPart} min ${seconds} sec`;
  } else {
    // Converting minutes to seconds
    const seconds = Math.round(minutes * 60);

    // Formatting the result
    formattedResult = `${seconds} sec`;
  }

  return formattedResult;
}

function formatSecondsToMinutesAndSeconds(seconds) {
  let formattedResult;

  if (seconds > 60) {
    // Splitting into integer and decimal parts
    const integerPart = Math.floor(seconds / 60);
    const decimalPart = seconds / 60 - integerPart;

    // Converting decimal part to seconds
    const remainingSeconds = Math.round(decimalPart * 60);

    // Formatting the result
    formattedResult = `${integerPart} min ${remainingSeconds} sec`;
  } else {
    // Formatting the result
    formattedResult = `${seconds} sec`;
  }

  return formattedResult;
}

module.exports = {
  formatMinutesToMinutesAndSeconds,
  formatSecondsToMinutesAndSeconds,
};
