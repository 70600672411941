/* eslint-disable prefer-template */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable react/self-closing-comp */
/* eslint-disable arrow-body-style */

import { useEffect, useState } from 'react';
import './dailyStats.css';
import * as React from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import moment from 'moment-timezone';
import Skeleton from '@mui/material/Skeleton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';
import { Chart, AxisOptions } from 'react-charts';
import { CircularProgress } from '@mui/material';
import DatePicker from 'react-datepicker';
import { formatMinutesToMinutesAndSeconds } from '../../utils/formatDuration';
import Subcription from '../Subcription/Subcription';
import { Instance } from '../../config/Http';
import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePickerInput = React.forwardRef(({ value, onClick, className }, ref) => (
  <button className="d-flex custom-date-picker-input" onClick={onClick} ref={ref}>
    {value} <span className="fa fa-chevron-down align-self-center" style={{ fontSize: '10px', paddingLeft: '6px' }} />
  </button>
));

const DailyStats = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState({});
  const [timeZone, setTimeZone] = useState('');
  const [chartData, setChartData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    const getTimeZone = () => {
      const { timeZone: localTimeZone } = Intl.DateTimeFormat().resolvedOptions();
      setTimeZone(localTimeZone);
      fetchData();
    };

    getTimeZone();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const { timeZone: localTimeZone } = Intl.DateTimeFormat().resolvedOptions();
      const summaryResponse = await Instance.get(
        `/users/outbound/daily-summary?timezone=${localTimeZone}&date=${moment(new Date()).format('YYYY-MM-DD')}`
      );
      const dailyStats = summaryResponse.data.summary.today;

      setChartData([
        {
          label: 'Calls Connected',
          data: [
            {
              title: 'Calls Connected',
              value: dailyStats.totalCallsConnected,
            },
          ],
        },
        {
          label: 'No Answer/Voicemail',
          data: [
            {
              title: 'No Answer/Voicemail',
              value: dailyStats.totalCallsNoAnswerVoicemail,
            },
          ],
        },
        {
          label: 'DNC',
          data: [
            {
              title: 'DNC',
              value: dailyStats.totalCallsDNC,
            },
          ],
        },
        {
          label: 'Human Followups',
          data: [
            {
              title: 'Human Followups',
              value: dailyStats.totalCallsHumanFollowup,
            },
          ],
        },
        {
          label: 'Appointments Booked',
          data: [
            {
              title: 'Appointments Booked',
              value: dailyStats.totalCallsAppointments,
            },
          ],
        },
        {
          label: 'Live Transfers',
          data: [
            {
              title: 'Live Transfers',
              value: dailyStats.totalLiveTransfers,
            },
          ],
        },
      ]);

      setSummary(summaryResponse.data.summary);
      setLoading(false);
      setStartDate(new Date());
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const fetchDataByDate = async (date) => {
    setLoading(true);
    try {
      const summaryResponse = await Instance.get(
        `/users/outbound/daily-summary?timezone=${timeZone}&date=${moment(date)}`
      );
      const dailyStats = summaryResponse.data.summary.today;

      setChartData([
        {
          label: 'Calls Connected',
          data: [
            {
              title: 'Calls Connected',
              value: dailyStats.totalCallsConnected,
            },
          ],
        },
        {
          label: 'No Answer/Voicemail',
          data: [
            {
              title: 'No Answer/Voicemail',
              value: dailyStats.totalCallsNoAnswerVoicemail,
            },
          ],
        },
        {
          label: 'DNC',
          data: [
            {
              title: 'DNC',
              value: dailyStats.totalCallsDNC,
            },
          ],
        },
        {
          label: 'Human Followups',
          data: [
            {
              title: 'Human Followups',
              value: dailyStats.totalCallsHumanFollowup,
            },
          ],
        },
        {
          label: 'Appointments Booked',
          data: [
            {
              title: 'Appointments Booked',
              value: dailyStats.totalCallsAppointments,
            },
          ],
        },
        {
          label: 'Live Transfers',
          data: [
            {
              title: 'Live Transfers',
              value: dailyStats.totalLiveTransfers,
            },
          ],
        },
      ]);

      setSummary(summaryResponse.data.summary);
      setLoading(false);
      setStartDate(date);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const primaryAxis = React.useMemo(
    () => ({
      getValue: (datum) => datum.title,
      tickLabelStyle: {
        fontSize: 20,
      },
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: (datum) => datum.value,
        min: 0,
        hardMin: 0,
      },
    ],
    []
  );

  return (
    <>
      <div className="transaction-card p-4">
        <h5 className="text-start w-100 mb-1">
          <i className="me-4" onClick={() => navigate(-1)}>
            <KeyboardBackspaceIcon color="primary" fontSize="small" />
          </i>
          Voice AI Statistics &nbsp;&nbsp;
          <Button variant="contained" color="primary" size="small" startIcon={<RefreshIcon />} onClick={fetchData}>
            Refresh
          </Button>
        </h5>

        <h5>
          <div className="me-4 d-flex justify-content-center mt-4">
            Showing stats for
            <DatePicker
              minDate={moment().subtract(5, 'days').toDate()}
              maxDate={new Date()}
              selected={startDate}
              onChange={(date) => fetchDataByDate(date)}
              dateFormat="MM/dd/yyyy"
              customInput={<CustomDatePickerInput className="example-custom-input" />}
            />
          </div>
        </h5>

        {loading ? (
          <div className="d-flex justify-content-center align-items-center m-4">
            <CircularProgress style={{ color: '#000', fontSize: '24px' }} />
          </div>
        ) : (
          <>
            <div className="m-4">
              {summary && summary.today && (
                <div className="summary-cards">
                  <div className="card">
                    <div className="card-header" style={{ background: 'none', border: 'none' }}>
                      <p>Voice AI Calls</p>
                      <h2 className="card-price">{summary.today.totalCalls}</h2>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" style={{ background: 'none', border: 'none' }}>
                      <p>Calls Connected</p>
                      <h2 className="card-price">{summary.today.totalCallsConnected}</h2>
                      {summary.today.totalCallsConnected > 0 && (
                        <small>Percent of calls connected - {summary.today.percentCallsConnected}%</small>
                      )}
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" style={{ background: 'none', border: 'none' }}>
                      <p>No Answer/Voicemail</p>
                      <h2 className="card-price">{summary.today.totalCallsNoAnswerVoicemail}</h2>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" style={{ background: 'none', border: 'none' }}>
                      <p>DNC</p>
                      <h2 className="card-price">{summary.today.totalCallsDNC}</h2>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" style={{ background: 'none', border: 'none' }}>
                      <p>Human Followups</p>
                      <h2 className="card-price">{summary.today.totalCallsHumanFollowup}</h2>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" style={{ background: 'none', border: 'none' }}>
                      <p>Appointments Booked</p>
                      <h2 className="card-price">{summary.today.totalCallsAppointments}</h2>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" style={{ background: 'none', border: 'none' }}>
                      <p>Live Transfers</p>
                      <h2 className="card-price">{summary.today.totalLiveTransfers}</h2>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center mb-4">
              <div style={{ height: '400px', width: '800px' }}>
                {/* horizontal bar graph */}
                {chartData && chartData.length > 0 && (
                  <Chart
                    options={{
                      data: chartData,
                      primaryAxis: primaryAxis,
                      secondaryAxes: secondaryAxes,
                      getSeriesStyle: (series) => ({
                        width: '20px',
                      }),
                    }}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DailyStats;
