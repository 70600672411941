import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, createTheme, ThemeProvider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Swal from 'sweetalert2';
import { Instance } from '../../../config/Http';
import Iconify from '../../../components/iconify';
// import Swal from 'sweetalert2';

// ----------------------------------------------------------------------

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '2px solid white',
              color: 'white', // Replace 'red' with your desired border color
            },
            '&:hover fieldset': {
              borderColor: '#5756a2', // Replace 'blue' with your desired hover color
            },
          },
        },
      },
    },
  },
});

export default function SignupForm() {
  const [loader, setLoader] = useState(false);
  // const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { email } = useParams();

  const queryParams = new URLSearchParams(window.location.search);
  const locationId = queryParams.get('locationId');

  const Navigate = useNavigate();
  const landlogin = () => {
    setLoader(true);

    const login = new FormData();
    login.append('email', email.toLowerCase());
    login.append('password', password);

    Instance.post(`auth/register`, { email, password, locationId })
      .then((response) => {
        localStorage.setItem('accessToken', response?.data?.accessToken);
        localStorage.setItem('refreshToken', response?.data?.refreshToken);
        localStorage.setItem('locationId', locationId);
        localStorage.setItem('email', email.toLowerCase());
        setLoader(false);

        if (response.status === 200) {
          Navigate('/dashboard/app');
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((resError) => {
        setLoader(false);
        Swal.fire({
          title: resError.response.data.message,
          icon: 'error',
        });
      });
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <form onSubmit={landlogin}>
        <Stack spacing={3}>
          <div>
            Account: <strong>{locationId}</strong>
            <br />
            Email: <strong>{email.toLowerCase()}</strong>
          </div>
          <ThemeProvider theme={theme}>
            <TextField
              name="Password"
              label="Password"
              placeholder="Enter account password"
              onChange={(e) => setPassword(e.target.value)}
              variant="filled"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{}}>
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </ThemeProvider>
        </Stack>

        <LoadingButton
          loading={loader}
          loadingPosition="start"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ marginTop: 5, color: '#fff', backgroundColor: '#012367' }}
          onClick={landlogin}
        >
          Signup
        </LoadingButton>
      </form>
    </>
  );
}
