import { useEffect } from 'react';
/* eslint-disable import/no-useless-path-segments */
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Avatar, Box, Container, Typography } from '@mui/material';
// sections
import { Link, useParams, useLocation, Navigate, useNavigate } from 'react-router-dom';
import SignupForm from '../../src/sections/auth/signup/SignupForm';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    backgroundColor: 'whitesmoke',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 600,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(1, 5),
}));

function SignupPage() {
  const navigate = useNavigate();
  const { email } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const locationId = queryParams.get('locationId');

  useEffect(() => {
    if (!email || !locationId) {
      navigate('/404');
    } else {
      const auth = localStorage.getItem('accessToken');

      if (auth) {
        navigate(`/dashboard/app/`);
      }
    }
  }, [email]);

  return (
    <>
      <Helmet>
        <title> Signup | BFD - Admin </title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <div className="rounded" style={{ background: 'white', padding: '30px' }}>
              <Typography clas variant="h3" sx={{ mt: 5, mb: 8, mx: 'auto', color: 'black' }}>
                Create your account
              </Typography>
              <SignupForm />
              <div className="d-flex flex-column" style={{ marginTop: '20px' }}>
                <Link className="my-2 text-black" to={`/login/${email}?locationId=${locationId}`}>
                  Already have an account? Click here to login
                </Link>
              </div>
            </div>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

export default SignupPage;
