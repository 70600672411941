import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Avatar, Box, Container, Typography } from '@mui/material';
// sections
// import { Link } from 'react-router-dom';
import { Link, useParams, useLocation, Navigate, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { LoginForm } from '../sections/auth/login';
import { Instance } from '../config/Http';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    backgroundColor: 'whitesmoke',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 600,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(1, 5),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const { email } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const locationId = queryParams.get('locationId');

  const navigate = useNavigate();

  useEffect(() => {
    if (!email || !locationId) {
      navigate('/404');
    } else {
      const emailLower = email.toLowerCase();
      const previousEmail = localStorage.getItem('email');
      if (previousEmail) {
        if (previousEmail !== emailLower) {
          Instance.get(`auth/checkEmail/${emailLower}`)
            .then((res) => {
              if (!res.data.emailExist) {
                Swal.fire({
                  title: 'Account not found',
                  text: 'There is no account regarding this email you have to register first.',
                  icon: 'error',
                  dangerMode: true,
                });
                localStorage.clear();
              }
            })
            .catch((err) => {});
        } else {
          const sessionExpired = localStorage.getItem('sessionExpired');
          if (!sessionExpired) {
            const auth = localStorage.getItem('accessToken');
            if (auth) {
              navigate(`/dashboard/app/`);
            }
          }
        }
      }
    }
  }, [email]);

  return (
    <>
      <Helmet>
        <title> Login </title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <div className="rounded" style={{ background: 'white', padding: '30px' }}>
              <Typography clas variant="h3" sx={{ mt: 5, mb: 8, mx: 'auto', color: 'black' }}>
                Login to your account
              </Typography>
              <LoginForm />
              <div className="d-flex flex-column" style={{ marginTop: '20px' }}>
                <Link className="my-2 text-black" to={`/signup/${email}?locationId=${locationId}`}>
                  Don't have an account? Click here to register
                </Link>
              </div>
            </div>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
