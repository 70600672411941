import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import Page404 from './pages/Page404';

import DashboardAppPage from './pages/DashboardAppPage';
import SignupPage from './pages/SignupPage';
import Transaction from './components/Transaction/Transaction';
import LoginPage from './pages/LoginPage';
import RequireAuth from './utils/RequiredAuth';
import ForgotPage from './pages/ForgotPage';
import OtpPage from './pages/OtpPage';
import TgTransaction from './components/TgTransaction/TgTransaction';
import DailyStats from './components/DailyStats/DailyStats';

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/" />, index: true },
        {
          path: 'app',
          element: (
            <RequireAuth>
              <DashboardAppPage />
            </RequireAuth>
          ),
        },
        { path: 'transaction', element: <Transaction /> },
        { path: 'voice-ai-daily-stats', element: <DailyStats /> },
        { path: 'tg-transaction', element: <TgTransaction /> },
      ],
    },
    {
      path: '/',
      element: (
        <RequireAuth>
          <DashboardAppPage />
        </RequireAuth>
      ),
    },
    {
      path: 'signup/:email',
      element: <SignupPage />,
    },
    {
      path: 'login/:email',
      element: <LoginPage />,
    },
    {
      path: 'forgot/:email',
      element: <ForgotPage />,
    },
    {
      path: 'otp/:email',
      element: <OtpPage />,
    },
    {
      path: '*',
      element: <Page404 />,
    },
  ]);

  return routes;
}
