import React, { useEffect } from 'react';
import { Navigate, useLocation, Link as RouterLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Button, CircularProgress, Container, styled, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import LoginPage from '../pages/LoginPage';
import { Instance } from '../config/Http';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const RequireAuth = ({ props, children }) => {
  const [loading, setLoading] = React.useState(true);
  const [valid, setValid] = React.useState(false);
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const email = localStorage.getItem('email');
  const location = useLocation();

  // eslint-disable-next-line consistent-return
  async function validateSession() {
    try {
      if (!accessToken || !refreshToken) {
        return <Navigate to={`/login/${email}`} state={{ from: location }} replace />;
      }

      const response = await Instance.post('/auth/session', { accessToken, refreshToken });
      if (response.status === 200) {
        if (response.data.status === 'session-refreshed') {
          localStorage.setItem('accessToken', response.data.accessToken);
          localStorage.setItem('refreshToken', response.data.refreshToken);
          setLoading(false);
          setValid(true);
        } else if (response.data.status === 'valid-session') {
          setLoading(false);
          setValid(true);
        }
      } else {
        localStorage.setItem('sessionExpired', true);
        setLoading(false);
      }
    } catch (error) {
      localStorage.setItem('sessionExpired', true);
      setLoading(false);
    }
  }

  useEffect(() => {
    validateSession();
  }, []);

  return loading ? (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      {' '}
      <CircularProgress variant="indeterminate" />
    </div>
  ) : valid ? (
    children
  ) : (
    <>
      <Helmet>
        <title>Session Expired</title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Session Expired
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Because of inactivity, your session has expired. Please login again.
          </Typography>

          <Button
            className="my-4"
            to="/"
            size="large"
            variant="contained"
            href={`/login/${email}?locationId=${localStorage.getItem('locationId')}`}
          >
            Login
          </Button>
        </StyledContent>
      </Container>
    </>
  );
};

export default RequireAuth;
