/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import moment from 'moment';

const Histroy = ({
  id = '',
  created = 0,
  card = {},
  status = '',
  amount = 0,
  currency = '',
  receiptUrl = '',
  description = '',
}) => {
  return (
    <>
      <Accordion className="mb-0">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="py-2 pb-0 px-4"
        >
          <div className="d-flex flex-column w-100 p-0">
            <div className="d-flex justify-content-between w-100">
              <div className="fs-7 fw-bolder">{moment.unix(created).format('MMM DD, YYYY')}</div>
              {receiptUrl && receiptUrl !== '' ? (
                <a href={receiptUrl} target="_blank" rel="noreferrer">
                  <div className="fs-7">View Receipt</div>
                </a>
              ) : (
                <div className="fs-7">No receipt available</div>
              )}
              <div className="truncate">
                {card.brand} •••• {card.last4}
              </div>
              <div>
                <i>
                  {status === 'failed' ? (
                    <CloseRoundedIcon fontSize="small" color="error" />
                  ) : (
                    <CheckCircleIcon fontSize="small" color="success" />
                  )}
                </i>{' '}
                {status}
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="py-0 px-4">
          <div className="d-flex flex-column justify-content-between w-100">
            <div className="d-flex justify-content-between w-100 py-0">
              <label className="fs-7 me-3 font-weight-bold">Invoice Description</label>
              <label className="fs-7 font-weight-bold">Amount</label>
            </div>
            <div className="d-flex justify-content-between w-100">
              <p className="fs-8 me-3">{description && description !== '' ? description : 'No description added'}</p>
              <p className="">
                {amount} {currency.toUpperCase()}
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Histroy;
